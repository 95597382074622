import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import richText from "../serializers/richText"
import classNames from "classnames"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default () => {
  const data = useStaticQuery(graphql`
    {
      allSanityPlaysPage {
        nodes {
          seo {
            metaTitle
            metaDescription
            openGraphTitle
            openGraphDescription
            openGraphImage {
              asset {
                url
              }
            }
            twitterTitle
            twitterDescription
            twitterImage {
              asset {
                url
              }
            }
          }
          description: _rawDescription
          plays {
            _key
            year
            title
            writer
            theater
            description
            notes: _rawNotes
            characters {
              _key
              title
              description
            }
            images {
              _key
              alt
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  const { seo, description, plays = [] } = data.allSanityPlaysPage.nodes[0]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    fade: true,
    easing: "ease",
  }

  return (
    <Layout>
      <SEO title="Plays" {...seo} />
      <section className="bio grid-container contained">
        <section className="row">
          <aside className="col">
            <div className="columns--desktop rich-text serif--sm serif--md--md">
              <BlockContent blocks={description} serializers={richText} />
            </div>
          </aside>
        </section>

        {plays.length > 0 && (
          <React.Fragment>
            <section class="row pt2 pt5--md pt10--lg">
              <div className="col">
                <p className="serif--md serif--lg--md x pb1 pt5 pt2--lg fw--800">
                  Plays
                </p>
              </div>
            </section>

            {plays.map((play, index) => {
              return (
                <section
                  class={classNames("row", {
                    "pt2 pt5--md": index > 0,
                  })}
                  key={`play_${index}`}
                >
                  <div class="col c12">
                    <div class="row">
                      <div class="col c5--lg">
                        <strong className="serif--md serif--lg--md serif--xl--lg">
                          {play.title}
                        </strong>

                        {play?.images.length > 0 && (
                          <div class="hide--lg pt2 pb2 row align--center">
                            <Slider className="col c11" {...sliderSettings}>
                              {play.images.map((img, index) => (
                                <picture>
                                  <source
                                    srcSet={`${img.image.asset.url}?w=1000&auto=format&q=75`}
                                    media="(min-width: 600px)"
                                  />
                                  <img
                                    alt={img.alt}
                                    className="db"
                                    src={`${img.image.asset.url}?w=600&auto=format`}
                                  />
                                </picture>
                              ))}
                            </Slider>
                          </div>
                        )}

                        {play.description && (
                          <p className="pt2 serif--sm serif--md--md rich-text">
                            {play.description}
                          </p>
                        )}

                        {play.notes && (
                          <div className="serif--sm serif--md--md pt2 rich-text">
                            <strong className="pb1 db">Notes</strong>
                            <BlockContent
                              blocks={play.notes}
                              serializers={richText}
                            />
                          </div>
                        )}

                        {play.characters.length > 0 && (
                          <div className="serif--sm serif--md--md pt2">
                            <strong className="pb1 db">Characters</strong>
                            {play.characters.map((character, index) => (
                              <p
                                className={classNames("", {
                                  "pt1 pt2--lg": index > 0,
                                })}
                                key={character._key}
                              >
                                <em>{character.title}, </em>
                                {character.description}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                      <div class="col c2--lg" />
                      <div class="col c5--lg show--lg">
                        {play?.images.length > 0 && (
                          <div class="pt2">
                            <Slider {...sliderSettings}>
                              {play.images.map((img, index) => (
                                <picture>
                                  <source
                                    srcSet={`${img.image.asset.url}?w=1000&auto=format&q=75`}
                                    media="(min-width: 600px)"
                                  />
                                  <img
                                    alt={img.alt}
                                    className="db"
                                    src={`${img.image.asset.url}?w=600&auto=format`}
                                  />
                                </picture>
                              ))}
                            </Slider>
                          </div>
                        )}
                        {/* {play.characters && (
                          <div className=" serif--sm serif--md--md pt2">
                            <strong className="pb1 db">Characters</strong>
                            {play.characters.map((character, index) => (
                              <p
                                className={classNames("", {
                                  "pt1 pt2--lg": index > 0,
                                })}
                                key={character._key}
                              >
                                <em>{character.title}, </em>
                                {character.description}
                              </p>
                            ))}
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </section>
              )
            })}
          </React.Fragment>
        )}
      </section>
    </Layout>
  )
}
